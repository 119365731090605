import Vue from 'vue';
import Vuex from 'vuex';

import common from '@/store/modules/common.store';
import config from '@/store/modules/config.store';
import programs from '@/store/modules/programs.store';
import companies from '@/store/modules/companies.store';
import roles from '@/store/modules/roles.store';
import dashboard from '@/store/modules/dashboard.store';
import permissions from '@/store/modules/permissions.store';
import entities from '@/store/modules/entities.store';
import team from '@/store/modules/team.store';
import resources from '@/store/modules/resources.store';
import configuration from '@/store/modules/configuration.store';
import stepperform from '@/store/modules/stepperform.store';
import tasks from '@/store/modules/tasks.store';
import faviconTitle from '@/store/modules/faviconTitle.store';
import user from '@/store/modules/user.store';
import requirementCategoryEditModal from '@/store/modules/components/requirementCategoryEditModal.store';
import ui from '@/store/modules/ui.store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        config,
        programs,
        companies,
        roles,
        dashboard,
        permissions,
        entities,
        team,
        resources,
        stepperform,
        faviconTitle,
        configuration,
        tasks,
        requirementCategoryEditModal,
        ui,
        common,
        user,
    },
    strict: process.env.NODE_ENV !== 'production',
});
