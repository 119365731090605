<template>
    <div v-if="permissionsLoaded">
        <template v-if="companyRoleUserCollection.isLoaded()">
            <div class="profile-team-list">
                <div
                    v-for="(item, index) in teamList"
                    :key="`current-${index}`"
                    class="profile-team-list-item"
                >
                    <div
                        v-if="item.company_user_id"
                        class="profile-team-item"
                        @click="$router.push(`/Profile/${item.user.id}`)"
                    >
                        <HtAvatar
                            :user="$Utils.getAvatarData(item.user)"
                        />
                        <div class="team-item-content">
                            <div class="team-item-name">
                                {{ item.user.firstname }} {{ item.user.lastname.toUpperCase() }}
                            </div>
                            <div class="team-item-role">
                                {{ canEdit ? item.role.alias_translated : item.role }}
                            </div>
                        </div>
                        <div class="team-item-icons">
                            <button
                                v-if="canEdit"
                                @click="remove($event, item)"
                            >
                                <HtIcon
                                    size="20"
                                    stroke-width="2"
                                    name="xmark"
                                />
                            </button>
                            <button
                                v-if="canEdit"
                                @click="change($event, item)"
                            >
                                <HtIcon
                                    size="20"
                                    stroke-width="2"
                                    name="edit-pencil"
                                />
                            </button>
                            <HtIcon
                                size="20"
                                stroke-width="2"
                                name="nav-arrow-right"
                            />
                        </div>
                    </div>
                    <button
                        v-else
                        class="profile-team-add"
                        @click="change($event, item)"
                    >
                        <HtIcon
                            stroke-width="2"
                            name="add-circle"
                        />

                        <div
                            class="team-role-text"
                        >
                            <t>Add</t>
                            <span v-if="item.role"> {{ item.role.alias_translated }}</span>
                        </div>
                    </button>
                </div>
            </div>

            <!-- <template v-if="!isEdit">
                <div
                    v-if="getUserTeam.length"
                    class="card-list flex-container card-onboarding onboarding-team"
                >
                    <div
                        v-for="(item, index) in getUserTeam"
                        :key="`current-${index}`"
                        v-tooltip="item.user.firstname + ' ' + item.user.lastname"
                        class="item transparent user-selected"
                        @click="$router.push(`/Profile/${item.user.id}`)"
                    >
                        <div
                            :key="`${item.company_user_id}-${index}`"
                            class="item-content"
                        >
                            <div class="item-title">
                                {{ item.user.firstname }}
                                <div class="team-role">
                                    {{ item.role }}
                                </div>
                            </div>
                            <div
                                v-user-image="{ image: item.user.image,
                                                firstname: item.user.firstname,
                                                lastname: item.user.lastname,
                                                size: 5}"
                                class="item-img full"
                            />
                            <div class="team-member-overlay" />
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="empty-component mb-30"
                >
                    <strong>
                        <t>No supervisors on this user</t>
                    </strong>
                </div>
            </template>
            <template v-else>
                <div class="profile-onboarding-team-body">
                    <div class="flex-container card-onboarding onboarding-team">
                        <div
                            v-for="(item, index) in companyRoleUserCollection.models"
                            :key="`change-${index}`"
                            :class="['item transparent', {'user-selected': item.company_user_id}]"
                            @click="onClick(item)"
                        >
                            <div
                                v-if="!item.company_user_id"
                                :key="index"
                                class="item-content"
                            >
                                <div class="icon-wrapper">
                                    <span class="icon icon-plus">+</span>
                                </div>
                                <div
                                    v-if="item.role"
                                    class="item-title"
                                >
                                    <span>{{ item.role.alias_translated }}</span>
                                </div>

                                <div class="item-img" />
                            </div>

                            <div
                                v-else
                                :key="`${item.company_user_id}-${index}`"
                                class="item-content"
                            >
                                <div class="icons-wrapper">
                                    <HtButton
                                        type=""
                                        size="small"
                                        class="icon-button"
                                    >
                                        <IconChange class="change" />
                                    </HtButton>
                                    <HtButton
                                        type=""
                                        size="small"
                                        class="icon-button"
                                        @click.native="remove($event, item)"
                                    >
                                        <FontAwesomeIcon
                                            icon="trash"
                                            class="delete"
                                        />
                                    </HtButton>
                                </div>
                                <div class="item-title">
                                    {{ item.user.firstname }}

                                    <template v-if="item.role">
                                        <div class="team-role">
                                            {{ item.role.alias_translated }}
                                        </div>
                                    </template>
                                </div>

                                <div
                                    v-user-image="{
                                        image: item.user.image,
                                        firstname: item.user.firstname,
                                        lastname: item.user.lastname,
                                        size: 5}"
                                    class="item-img full"
                                />
                                <div class="team-member-overlay" />
                            </div>
                        </div>
                    </div>
                </div>
            </template> -->
        </template>
        <template v-else>
            <HtIconLoading />
        </template>
    </div>
</template>
<script>

import SupervisorMixin from '@/mixins/SupervisorMixin';
import modalMixin from '@/components/globals/modals/modalMixin';

export default {
    name: 'ProfileTeamList',
    permissions: [
        'ModelCompanyUser',
    ],
    mixins: [
        SupervisorMixin,
        modalMixin,
    ],
    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {

        getUserTeam() {
            const userTeam = [];
            if (this.companyRoleUserCollection.isLoaded()) {
                this.companyRoleUserCollection.$.models.each((companyRoleUser) => {
                    const member = userTeam.find((s) => s.company_user_id === companyRoleUser.company_user_id);
                    if (member) {
                        const role = companyRoleUser.role.alias_translated;
                        member.role += `, ${role}`;
                    } else {
                        const role = companyRoleUser.role.alias_translated;
                        userTeam.push({ ...companyRoleUser, role });
                    }
                });
            }
            return userTeam;
        },
        teamList() {
            return this.canEdit ? this.companyRoleUserCollection.models : this.getUserTeam;
        },
    },
    watch: {
        isEdit() {
            this.companyRoleUserCollection.fromSaveState();
            this.mergeEmptyRolesIntoSupervisors();
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            await Promise.all([
                this.getSupervisors(this.companyUserId),
                this.getRoles(),
            ]);
            this.mergeEmptyRolesIntoSupervisors();
        },
        change(e, item) {
            e.stopPropagation();
            this.$emit('change', item);
        },
        remove(e, item) {
            e.stopPropagation();
            this.genericConfirm(
                this.translate('Are you sure you want to delete this role ?'),
                this.translate(
                    'By deleting it, all pending task attributed to this role will be deleted. You will still be able to manually add them.',
                ),
            ).then(() => {
                this.$emit('remove', item);
            });
        },
        async onValidate() {
            try {
                await this.updateSupervisors(this.companyUserId);
                this.$emit('update:isEdit', false);
                this.$Notifier('App').showInfo(this.translate('Successfully updated'));
                this.loadData();
            } catch (error) {
                this.$Notifier('App').showError(this.translate('An error occured'));
                this.buttonState = 'idle';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";
@import '~@/styles/var';

.profile-team-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 14px 8px;
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    box-shadow: var(--shadow-1);
}

.profile-team-list-item {
    flex: 0 1 calc(100% - 12px);
    margin: 0 6px;
    margin-bottom: 12px;
}

.profile-team-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px;
    border-radius: var(--radius);
    background-color: var(--fill-primary);
    border: 1px solid var(--border-primary);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    cursor: pointer;
    .ht-avatar {
        margin-right: 12px;
        flex-shrink: 0;
    }
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
}

.team-item-content {
    display: flex;
    flex-direction: column;
    margin-right: auto;
}

.team-item-name {
    @include ht-body;
}

.team-item-role {
    @include ht-caption;
    color: var(--text-secondary);
}

.team-item-icons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.profile-team-add {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    border: 1px dashed var(--border-secondary);
    border-radius: var(--radius);
    @include ht-body;
    .ht-icon {
        margin-right: 8px;
    }
}

@media (min-width: $tablet-large) {
    .profile-team-list-item {
        flex: 0 1 calc(50% - 12px);
    }
}
@media (min-width: $desktop-small) {
    .profile-team-list-item {
        flex: 0 1 calc(33% - 12px);
    }
}
</style>
