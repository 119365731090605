<template>
    <div>
        <Actions v-if="oldUi" />
        <NewActions v-else />
    </div>
</template>
<script>
import Actions from './Actions.vue';
import NewActions from './NewActions.vue';

export default {
    name: 'ActionsPage',
    components: {
        NewActions,
        Actions,
    },
    computed: {
        oldUi() {
            return this.$store.state.ui.oldUi;
        },
    },
};
</script>
