<template>
    <div
        v-if="!oldUi"
        class="profile-resources"
    >
        <div
            v-if="permissionsLoaded"
        >
            <NewUserSoftwareProfile
                v-if="$canRead('ModelCompanyUserSoftware', {company_user_id: companyUserId})"
                class="mb-4"
                :company-user-id="companyUserId"
                @on-load="onLoad"
            />

            <NewUserEquipmentProfile
                v-if="$canRead('ModelCompanyUserEquipment', {company_user_id: companyUserId})"
                class="mb-4"
                :company-user-id="companyUserId"
                @on-load="onLoad"
            />

            <NewUserSurveyProfile
                v-if="$canRead('AbstractComponentUserSurveyList', {company_user_id: companyUserId})"
                class="mb-4"
                :company-user-id="companyUserId"
                @on-load="onLoad"
            />

            <NewUserQuizProfile
                v-if="$canRead('ModelCompanyUserQuiz', {company_user_id: companyUserId}) ||
                    $canRead('ModelCompanyUserQuiz', {company_user_participant_id: companyUserId})"
                class="mb-4"
                :company-user-id="companyUserId"
                @on-load="onLoad"
            />
            <NewUserDocumentProfile
                v-if="$canRead('ModelCompanyUserDoc', {company_user_id: companyUserId})"
                :company-user-id="companyUserId"
                class="mb-4"
                @on-load="onLoad"
            />
            <NewUserTrainingProfile
                v-if="$canRead('ModelCompanyUserDocument', {company_user_id: companyUserId})"
                :company-user-id="companyUserId"
                class="mb-4"
                @on-load="onLoad"
            />
            <NewUserPlanningEventProfile
                v-if="$canRead('AbstractComponentUserPlanningEventProfile', {company_user_id: companyUserId})"
                :company-user-id="companyUserId"
                class="mb-4"
                @on-load="onLoad"
            />
            <NewUserNudgeProfile
                v-if="$canRead('ModelCompanyUserNudge', {company_user_id: companyUserId})"
                :company-user-id="companyUserId"
                @on-load="onLoad"
            />
        </div>

        <template v-else>
            <HtIconLoading />
        </template>
    </div>

    <div
        v-else-if="permissionsLoaded && oldUi"
        class="user-resources"
    >
        <HtCard class="mb-5">
            <template #title>
                <HtCardTitleBar
                    :title="translate('{count} Resource | {count} Resources', { count: nbUserResource})"
                    :show-button="false"
                    :show-search="false"
                />
            </template>
            <template #default>
                <UserSoftwareProfile
                    v-if="$canRead('ModelCompanyUserSoftware', {company_user_id: companyUserId})"
                    class="mb-4"
                    :company-user-id="companyUserId"
                    @on-load="onLoad"
                />

                <UserEquipmentProfile
                    v-if="$canRead('ModelCompanyUserEquipment', {company_user_id: companyUserId})"
                    class="mb-4"
                    :company-user-id="companyUserId"
                    @on-load="onLoad"
                />

                <UserSurveyProfile
                    v-if="$canRead('AbstractComponentUserSurveyList', {company_user_id: companyUserId})"
                    class="mb-4"
                    :company-user-id="companyUserId"
                    @on-load="onLoad"
                />

                <UserQuizProfile
                    v-if="$canRead('ModelCompanyUserQuiz', {company_user_id: companyUserId}) ||
                        $canRead('ModelCompanyUserQuiz', {company_user_participant_id: companyUserId})"
                    class="mb-4"
                    :company-user-id="companyUserId"
                    @on-load="onLoad"
                />
                <UserDocumentProfile
                    v-if="$canRead('ModelCompanyUserDoc', {company_user_id: companyUserId})"
                    :company-user-id="companyUserId"
                    class="mb-4"
                    @on-load="onLoad"
                />
                <UserTrainingProfile
                    v-if="$canRead('ModelCompanyUserDocument', {company_user_id: companyUserId})"
                    :company-user-id="companyUserId"
                    class="mb-4"
                    @on-load="onLoad"
                />
                <UserPlanningEventProfile
                    v-if="$canRead('AbstractComponentUserPlanningEventProfile', {company_user_id: companyUserId})"
                    :company-user-id="companyUserId"
                    class="mb-4"
                    @on-load="onLoad"
                />
                <UserNudgeProfile
                    v-if="$canRead('ModelCompanyUserNudge', {company_user_id: companyUserId})"
                    :company-user-id="companyUserId"
                    @on-load="onLoad"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import UserTrainingProfile from './resources/training/UserTrainingProfile.vue';
import UserEquipmentProfile from './resources/equipment/UserEquipmentProfile.vue';
import UserSurveyProfile from './resources/survey/UserSurveyProfile.vue';
import UserSoftwareProfile from './resources/software/UserSoftwareProfile.vue';
import UserQuizProfile from './resources/quiz/UserQuizProfile.vue';
import UserDocumentProfile from './resources/document/UserDocumentProfile.vue';
import UserPlanningEventProfile from './resources/event/UserPlanningEventProfile.vue';
import UserNudgeProfile from './resources/nudge/UserNudgeProfile.vue';
import NewUserTrainingProfile from './resources/training/NewUserTrainingProfile.vue';
import NewUserEquipmentProfile from './resources/equipment/NewUserEquipmentProfile.vue';
import NewUserSurveyProfile from './resources/survey/NewUserSurveyProfile.vue';
import NewUserSoftwareProfile from './resources/software/NewUserSoftwareProfile.vue';
import NewUserQuizProfile from './resources/quiz/NewUserQuizProfile.vue';
import NewUserDocumentProfile from './resources/document/NewUserDocumentProfile.vue';
import NewUserPlanningEventProfile from './resources/event/NewUserPlanningEventProfile.vue';
import NewUserNudgeProfile from './resources/nudge/NewUserNudgeProfile.vue';

export default {
    name: 'PageUserResources',
    permissions: [
        'AbstractComponentUserSurveyList',
        'ModelCompanyUserEquipment',
        'ModelCompanyUserDoc',
        'ModelCompanyUserNudge',
        'ModelCompanyUserDocument',
        'ModelCompanyUserSoftware',
        'ModelCompanyUserQuiz',
        'AbstractComponentUserPlanningEventProfile',
    ],

    components: {
        HtCardTitleBar,
        UserSurveyProfile,
        UserNudgeProfile,
        UserQuizProfile,
        UserDocumentProfile,
        UserSoftwareProfile,
        UserEquipmentProfile,
        UserTrainingProfile,
        UserPlanningEventProfile,
        NewUserTrainingProfile,
        NewUserEquipmentProfile,
        NewUserSurveyProfile,
        NewUserSoftwareProfile,
        NewUserQuizProfile,
        NewUserDocumentProfile,
        NewUserPlanningEventProfile,
        NewUserNudgeProfile,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            nbUserResource: 0,
        };
    },

    computed: {
        oldUi() {
            return this.$store.state.ui.oldUi;
        },
    },

    methods: {
        onAddResource() {
            this.$refs.modalableAddElement.open();
        },
        onLoad(count) {
            this.nbUserResource += count;
        },
        onReload() {

        },
    },
};
</script>
<style lang="scss" scoped>
.profile-resources {
    padding: 16px;
    margin-bottom: 16px;
    background-color: var(--fill-secondary);
    border-radius: var(--radius-block);
}
</style>
