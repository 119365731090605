<template>
    <div v-if="!oldUi && isLoaded">
        <router-view class="h-100" />
    </div>
    <PageWithSidebar v-else-if="oldUi && isLoaded">
        <template #title>
            <HtPageTitle>
                <t>Resources</t>
            </HtPageTitle>
        </template>

        <template #sidebar>
            <HtPageSidebar
                :pages="filteredPages"
                @update-page="setupPage"
            />
        </template>

        <template>
            <router-view class="h-100" />
        </template>
    </PageWithSidebar>
</template>

<script>
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';

export default {
    name: 'Resources',
    components: {
        HtPageTitle,
        HtPageSidebar,
        PageWithSidebar,
    },

    computed: {
        permissions() {
            return this.pages.map((page) => `Page${page.name}`);
        },

        filteredPages() {
            return this.pages.filter((page) => this.$can(`Page${page.name}`));
        },

        pages() {
            return [
                {
                    name: 'ResourcesDocuments',
                    label: this.translate('Documents'),
                },
                {
                    name: 'ResourcesTrainings',
                    label: this.translate('Trainings'),
                },
                {
                    name: 'ResourcesSurveys',
                    label: this.translate('Surveys'),
                },
                {
                    name: 'ResourcesQuizzes',
                    label: this.translate('Quizzes'),
                },
                {
                    name: 'ResourcesEvents',
                    label: this.translate('Events'),
                },
                {
                    name: 'ResourcesSoftware',
                    label: this.translate('Software'),
                },
                {
                    name: 'ResourcesEquipments',
                    label: this.translate('Equipments'),
                },
                {
                    name: 'ResourcesRequirements',
                    label: this.translate('Requirements'),
                },
                {
                    name: 'ResourcesEmails',
                    label: this.translate('Emails'),
                },
                {
                    name: 'ResourcesActions',
                    label: this.translate('Actions'),
                },
                {
                    name: 'ResourcesNudges',
                    label: this.translate('Nudges'),
                },
            ];
        },

        isLoaded() {
            return this.$store.getters['permissions/getPermissionsLoaded'](this.permissions);
        },
        oldUi() {
            return this.$store.state.ui.oldUi;
        },
    },

    watch: {
        $route() {
            this.redirect();
        },
    },

    created() {
        this.setupPage(this.$route.name);

        this.$loadPermissions(this.permissions).then(() => {
            this.redirect();
        });
    },

    methods: {
        redirect() {
            if (this.filteredPages.length > 0 && this.$route.name === 'Resources') {
                const { name } = this.filteredPages[0];

                this.$router.push({ name });

                this.setupPage(name);
            }
        },

        setupPage(name) {
            const page = this.pages.find((p) => p.name === name);

            if (page) {
                this.setPage({
                    title: page.label,
                    subtitles: [this.translate('Resources')],
                });
            }
        },
    },
};
</script>
