<template>
    <div v-if="!oldUi && isLoaded">
        <DashboardHeader />
        <router-view
            :key="forceReload"
            :dashboards="metabaseDashboards"
        />
        <FirstVisitPopup v-if="fromFlow" />
    </div>
    <PageWithSidebar v-else-if="oldUi && isLoaded">
        <template #title>
            <HtPageTitle>
                {{ title }}
            </HtPageTitle>
        </template>

        <template #sidebar>
            <HtPageSidebar
                :pages="filteredPages"
                @update-page="updatePage"
            />
        </template>

        <template>
            <router-view
                :key="forceReload"
                :dashboards="metabaseDashboards"
            />
        </template>
    </PageWithSidebar>
</template>

<script>
import DashboardHeader from '@/components/pages/dashboard/components/Header.vue';
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import UrlVariablesMixin from '@/components/mixins/UrlVariablesMixin';
import SatismeterMixin from '@/components/mixins/SatismeterMixin';
import FirstVisitPopup from '@/router/pages/dashboard/FirstVisitPopup.vue';

export default {
    name: 'PagesDashboard',
    components: {
        DashboardHeader,
        HtPageTitle,
        HtPageSidebar,
        PageWithSidebar,
        FirstVisitPopup,
    },
    mixins: [
        UrlVariablesMixin,
        SatismeterMixin,
    ],

    data() {
        return {
            forceReload: 1,
            isMetabaseLoaded: false,
            metabaseDashboards: [],
        };
    },

    computed: {
        fromFlow() {
            return this.$route.query.from === 'flow';
        },
        filteredPages() {
            return this.oldUi ? this.oldFilteredPages : this.newFilteredPages;
        },

        oldFilteredPages() {
            return this.pages.filter((p) => this.$can(`Page${p.name}`));
        },

        newFilteredPages() {
            return this.pages.filter((p) => p.name === 'DashboardActions').filter((p) => this.$can(`Page${p.name}`));
        },

        pages() {
            const pages = [
                {
                    name: 'DashboardStatistics',
                    label: this.translate('Dashboard'),
                },
                {
                    name: 'DashboardActions',
                    label: this.translate('My actions'),
                    count: this.$store.getters['dashboard/statistics/todoCount'],
                },
                {
                    name: 'DashboardHistory',
                    label: this.translate('History'),
                },
                {
                    name: 'DashboardReport',
                    label: this.translate('Report'),
                },
            ];

            // Quick and dirty... As requested in the ticket LIN-1719
            if ((this.$env.get('APP_ENV') === 'preprod' || this.$env.get('APP_ENV') === 'production') && this.shared.session.company.id === 1010) {
                return [
                    {
                        name: 'DashboardExternal',
                        label: this.translate('Finelive'),
                    },
                ].concat(pages);
            }

            if (this.metabaseDashboards.length > 0) {
                return pages.concat([
                    {
                        name: 'DashboardSpecificReports',
                        label: this.translate('Specific reports'),
                    },
                ]);
            }

            return pages;
        },

        permissions() {
            return [...(this.pages.map((p) => `Page${p.name}`)), 'PageDashboardSpecificReports'];
        },

        isLoaded() {
            return this.$store.getters['permissions/getPermissionsLoaded'](this.permissions) && this.isMetabaseLoaded;
        },
        todoCount() {
            return this.$store.getters['dashboard/statistics/todoCount'];
        },
        oldUi() {
            return this.$store.state.ui.oldUi;
        },
    },

    watch: {
        $route() {
            this.redirect();
        },
    },

    created() {
        this.$loadPermissions(this.permissions).then(() => {
            this.redirect();

            if (this.$can('PageDashboardSpecificReports')) {
                this.$http.get('dashboard/report/metabase-dashboards').then((response) => {
                    this.metabaseDashboards = response.data.dashboards;

                    this.isMetabaseLoaded = true;
                });
            } else {
                this.isMetabaseLoaded = true;
            }
        });

        this.setPage({ title: this.title });
        this.$store.dispatch('dashboard/statistics/fetchTodoCount');
    },

    methods: {
        redirect() {
            if (this.filteredPages.length > 0 && this.$route.name === 'Dashboard') {
                if (this.shared.session.companyUser.company_role_user.models.some((roleUser) => roleUser.role.is_actions_tab_displayed_at_login)) {
                    this.$router.push({ name: 'DashboardActions', query: this.$route.query });
                } else if (this.$can('PageDashboardStatistics') && !this.oldUi) {
                    this.$router.push({ name: 'PilotingStatistics' });
                } else if (this.filteredPages[0]) {
                    this.$router.push({ name: this.filteredPages[0].name, query: this.$route.query });
                }
            }
        },
        updatePage(name) {
            if (name === 'DashboardExternal') {
                this.forceReload += 1;
            }
        },
    },
};
</script>
