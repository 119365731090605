<template>
    <div v-if="permissionsLoaded">
        <div class="wrapper-dashboard">
            <div class="col-left-dashboard">
                <ProgramsMonitoringWidget
                    v-if="canHavePrograms"
                    :programs="programsToMonitor"
                    :nb-programs="nbPrograms"
                    :loading="loadingPrograms"
                />
                <Frieze
                    v-if="hasActiveProgram"
                    ref="frieze"
                />
                <div class="actions-wrapper">
                    <div class="ht-heading-3">
                        <t>Your next actions to take</t>
                    </div>
                    <RequirementsWidget v-if="$canRead('AbstractWidgetChecklistOnboardee')" />
                    <div class="wrapper-actions">
                        <div
                            v-if="canSeeQuickActionButton && !($store.getters['dashboard/actions/canViewProgramSummaries']
                                && (hasActiveProgram || $canRead('AbstractWidgetChecklistOnboardee')))"
                            style="display: flex; width: 100%; justify-content: end;"
                        >
                            <HtButton
                                :icon="['fal', 'plus']"
                                type="secondary"
                                @click.native="openQuickActionModal"
                            >
                                <t>Add</t>
                            </HtButton>
                        </div>
                        <ActionsWidget @tasksUpdate="onTasksUpdate" />
                    </div>
                </div>
            </div>
            <div class="col-right-dashboard">
                <AgendaWidget
                    v-if="hasUpcomingEvent"
                    :events="agendaWidgetEvents"
                />
                <InformationsWidget v-if="!isMobile && arrivalDateToCome" />
                <CompanyWidget />
                <NewMyTeamWidget
                    v-if="!isMobile && $store.getters['dashboard/actions/canViewMyTeam']"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ActionsWidget from '@/components/pages/dashboard/actions/ActionsWidget.vue';
import AgendaWidget from '@/components/pages/dashboard/widgets/AgendaWidget.vue';
import InformationsWidget from '@/components/pages/dashboard/widgets/InformationsWidget.vue';
import CompanyWidget from '@/components/pages/dashboard/widgets/CompanyWidget.vue';
import NewMyTeamWidget from '@/components/pages/dashboard/widgets/NewMyTeamWidget.vue';
import RequirementsWidget from '@/components/pages/dashboard/widgets/RequirementsWidget.vue';
import ProgramsMonitoringWidget from '@/components/pages/dashboard/widgets/ProgramsMonitoringWidget.vue';
import CompanyUser from '@/models/CompanyUser';
import ModalMixin from '@/components/globals/modals/modalMixin';
import QuickActionsModal from '@/components/globals/modals/QuickActions/QuickActionsModal.vue';
import HtButton from '@/components/globals/HtButton.vue';
import Frieze from '@/components/pages/dashboard/components/Frieze.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import listTasksMixin from '@/components/pages/actions/all/listTasksMixin';

import api from '@/store/api';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Actions',
    permissions: [
        'AbstractWidgetChecklistOnboardee',
        'AbstractCanSelfEnroll',
        'PageBulkEnrolling',
    ],
    components: {
        HtButton,
        AgendaWidget,
        InformationsWidget,
        CompanyWidget,
        NewMyTeamWidget,
        ActionsWidget,
        RequirementsWidget,
        ProgramsMonitoringWidget,
        Frieze,
    },
    mixins: [
        ModalMixin,
        listTasksMixin,
    ],
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
    data() {
        return {
            isMobile: false,
            agendaWidgetEvents: [],
            users: [],
            programsToMonitor: [],
            loadingPrograms: false,
            nbPrograms: 0,
            maxShownPrograms: 3,
            canHavePrograms: false,
        };
    },
    computed: {
        hasUpcomingEvent() {
            return this.agendaWidgetEvents.find((event) => !this.$Utils
                .moment(event.task.datetime_start).isBefore(new Date(), 'day'));
        },
        hasActiveProgram() {
            return this.$store.getters['dashboard/actions/activeProgramSummary'];
        },
        canSeeQuickActionButton() {
            return this.$can('PageBulkEnrolling') || this.$canRead('AbstractCanSelfEnroll');
        },
        companyUserArrivalDate() {
            return this.shared.session.companyUser.arrival_date;
        },
        arrivalDateToCome() {
            return new Date().getTime() - new Date(this.companyUserArrivalDate).getTime() <= 0;
        },
        companyUserId() {
            return this.shared.session.companyUser.id;
        },
    },
    watch: {
        'shared.session.companyUser.is_administrative_file_validated': function () {
            this.$refreshPermissions(this.$options.permissions, true);
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    created() {
        this.$store.dispatch('dashboard/actions/fetchProgramSummaries');
        this.$store.dispatch('dashboard/actions/fetchMyTeam');
        this.$store.dispatch('dashboard/actions/fetchRequirementCategoriesProgress');
        this.getProgramsToMonitor();

        api.user.planningEvents.getAllByUser(this.shared.session.companyUser.id, {
            withoutDelegated: true,
        }).then((response) => {
            this.agendaWidgetEvents = response.data.data;
        });
    },
    methods: {
        openQuickActionModal() {
            this.openCenterModal(QuickActionsModal);
        },
        onResize() {
            this.isMobile = window.innerWidth < 1024;
        },
        async getTasksUser(user) {
            if (user.nb_tasks) {
                return this.$store.dispatch(
                    'tasks/fetchCurrentUserTasks',
                    { id: user.id },
                ).then((result) => result.data.data);
            }
            return Promise.resolve([]);
        },
        sortedTasks(tasks) {
            if (!tasks) return [];

            return this.handleTasks(tasks);
        },
        async getProgramsToMonitor() {
            this.loadingPrograms = true;
            const users = await this.$store.dispatch('tasks/fetchCurrentUsersAndEmployees')
                .then((result) => result.data.data.filter((user) => user.id !== this.companyUserId));

            this.canHavePrograms = users.length > 0;
            if (this.canHavePrograms) {
                const apiCalls = [];

                for (let i = 0; i < users.length; i++) {
                    apiCalls.push(this.getTasksUser(users[i]));
                }

                const results = await Promise.all(apiCalls);
                results.forEach((tasks, index) => {
                    const sortedTasks = this.sortedTasks(tasks);
                    users[index].userTasks = sortedTasks;
                });

                const programsToMonitor = users.reduce((programs, user) => {
                    user.user_programs.forEach((program) => {
                        const isActiveAndNotDefault = program?.program?.type?.slug !== 'default' && program?.status === CompanyUserProgram.STATUS_ACTIVE;
                        const tasksInProgram = user.userTasks.filter((task) => task.user_program.id === program.id);

                        if (isActiveAndNotDefault && tasksInProgram.length) {
                            const { user_programs, userTasks, ...restOfUser } = user;
                            const nbOfLateTasks = tasksInProgram.reduce((nb, task) => {
                                if (this.$Utils.moment(task.datetime_end) <= this.$Utils.moment()) nb += 1;
                                return nb;
                            }, 0);

                            programs.push({
                                ...program,
                                user: restOfUser,
                                tasks: tasksInProgram,
                                lateTasks: nbOfLateTasks,
                            });
                        }
                    });

                    return programs;
                }, []).sort((a, b) => {
                    if (!a.lateTasks && !b.lateTasks) {
                        return b.tasks.length - a.tasks.length;
                    }
                    return b.lateTasks - a.lateTasks;
                });

                this.nbPrograms = programsToMonitor.length;
                this.programsToMonitor = programsToMonitor.slice(0, this.maxShownPrograms);
            }

            this.loadingPrograms = false;
        },
        onTasksUpdate() {
            if (this.$refs.frieze) this.$refs.frieze.update();
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.wrapper-dashboard {
    display: flex;
    flex-direction: column;
}
.col-right-dashboard {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.actions-wrapper {
    > .ht-heading-3 {
        margin-bottom: 12px;
    }
}
.widget-fade-enter-active, .widget-fade-leave-active {
  transition: opacity 0.3s ease;
}
.widget-fade-enter, .widget-fade-leave-to {
  opacity: 0;
}
.head-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}
.wrapper-my-program {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.col-my-program {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 3rem;
}

@media (min-width: $tablet-large){
    .wrapper-dashboard {
        flex-direction: row;
    }
    .col-left-dashboard {
        flex: 1 1 auto;
        min-width: 0;
    }
    .col-right-dashboard {
        flex: 0 0 auto;
        width: 300px;
        margin: 0 0 0 20px
    }
    .actions-wrapper {
        padding: 20px;
        border-radius: var(--radius-2);
        background-color: var(--fill-primary);
        border: 1px solid var(--border-primary);
        box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
        > .ht-heading-3 {
            margin-bottom: 20px;
        }
    }
    .wrapper-my-program {
        width: calc(100% + var(--gutter)*2);
        margin-left: calc(var(--gutter)*-1);
    }
    .col-my-program {
        width: calc(33.3333% - var(--gutter)*2);
        margin: 0 var(--gutter) 3rem;
    }
}
@media (min-width: $desktop-small){
    .col-right-dashboard {
        width: 370px;
    }
}
</style>
