<template>
    <div
        v-if="!oldUi"
        class="overflow-hidden"
    >
        <NewTeamFilters
            v-if="isLoaded"
            @viewChange="onViewChange"
        />
        <div class="team-list-page">
            <template v-if="isLoaded && !isLoading">
                <NewTeamList
                    :users="userPaginatorData"
                    :view="teamView"
                />

                <HtPagination
                    v-if="userPaginator.last_page > 1"
                    class="mb-5"
                    :value="userPaginator.current_page"
                    :last-page="userPaginator.last_page"
                    @input="fetchUsers"
                />
            </template>

            <HtIconLoading v-else />
        </div>
    </div>
    <div
        v-else
        class="overflow-hidden"
    >
        <TeamFilters v-if="isLoaded" />

        <template v-if="isLoaded && !isLoading">
            <TeamList
                :users="userPaginatorData"
            />

            <HtPagination
                class="mb-5"
                :value="userPaginator.current_page"
                :last-page="userPaginator.last_page"
                @input="fetchUsers"
            />
        </template>

        <IconLoading v-else />
    </div>
</template>

<script>
import HtPagination from '@/components/globals/HtPagination.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import TeamFilters from '@/components/pages/team/widget/TeamFilters.vue';
import NewTeamFilters from '@/components/pages/team/widget/NewTeamFilters.vue';
import CompanyUser from '@/models/CompanyUser';
import TeamList from '@/components/pages/team/TeamList.vue';
import NewTeamList from '@/components/pages/team/NewTeamList.vue';
import Auth from '@/services/Auth';
import moment from 'moment';

export default {
    name: 'TeamListPage',
    components: {
        TeamList,
        TeamFilters,
        NewTeamList,
        NewTeamFilters,
        HtPagination,
        IconLoading,
    },

    props: {
        activePage: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            teamView: 'list',
        };
    },

    computed: {
        oldUi() {
            return this.$store.state.ui.oldUi;
        },
        userPaginatorData() {
            if (this.activePage === 'TeamMyClass') {
                return this.$store.state.team.paginator.data.filter((data) => data.id !== this.shared.session.companyUser.id);
            }

            return this.$store.state.team.paginator.data;
        },

        userPaginator() {
            return this.$store.state.team.paginator;
        },

        isLoaded() {
            return this.$store.state.entities.entitiesLoaded;
        },

        isLoading() {
            return this.$store.state.team.loading
                || !this.$store.state.team.paginator;
        },
    },

    watch: {
        $route() {
            this.changeContext();
        },
    },

    created() {
        this.changeContext();
    },

    methods: {
        onViewChange(v) {
            this.teamView = v;
        },
        fetchUsers(page = null) {
            this.$store.dispatch('team/fetchUsers', page);
        },

        changeContext() {
            const isAllEmployees = this.$route.name === 'TeamAllEmployees';

            this.$store.dispatch('entities/fetchEntities');
            this.$store.commit('team/set_status_filter', this.$store.getters['team/userStatus'].filter((x) => x.slug === CompanyUser.STATUS_ACTIVE));

            if (isAllEmployees) {
                this.$store.commit('team/set_dates_filter', {
                    start: null,
                    end: null,
                });
            } else {
                const user = Auth.getLoggedUser();
                this.$store.commit('team/set_dates_filter', {
                    start: moment(user.active_program.main_key_date.starts_at).subtract(2, 'week').format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(user.active_program.main_key_date.starts_at).add(2, 'week').format('YYYY-MM-DD HH:mm:ss'),
                });
            }

            this.fetchUsers();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/styles/var';

    .ht-page-title {
        margin-bottom: 2rem;
        border-bottom: #E2E2E2 1px solid;
        padding: 2.2rem 2rem;
    }

    .team-directory-item {
        cursor: pointer;

        .team-user-image {
            height: 200px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 20px;
            border-top-left-radius: 0;
        }

        .team-user-wrapper {
            height: 90px;
            padding-top: 1em;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .team-user-name {
                font-family: $lato-bold;
                body:not(.old-ui) &{
                    font-family: var(--poppins);
                    font-weight: 600;
                }
            }

            .team-user-job-title {
                color: $color-text-grey;
                padding-top: 0.5em;
            }

            .team-user-email {
                font-size: 0.8em;
                padding-top: 0.7em;
            }
        }
    }

.team-list-page {
    padding: 10px;
    border-radius: var(--radius-block);
    background-color: var(--fill-secondary);
}
</style>
