const SET_OLD_UI = 'set_old_ui';

export default {
    namespaced: true,

    state() {
        return {
            oldUi: true,
        };
    },

    actions: {
        setOldUI({ commit }, oldUi) {
            commit(SET_OLD_UI, oldUi);
        },
    },

    mutations: {
        [SET_OLD_UI](state, oldUi) {
            if (oldUi == null) return;
            state.oldUi = oldUi;
        },
    },
};
