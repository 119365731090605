<template>
    <div
        v-if="isLoaded"
        class="mainbar-panels"
        :class="{'show-subbar': showSubbar}"
    >
        <div class="mainbar-panel">
            <HtTooltip
                v-if="canSeeQuickActionButton"
                inverted
                position="inline-end"
                class="mainbar-panel-btn-tooltip"
            >
                <HtButton
                    type="branding"
                    size="large"
                    class="mainbar-panel-btn"
                    @click="openQuickActionModal"
                >
                    <HtIcon
                        name="plus-circle"
                        size="16"
                    />
                    <span class="mainbar-panel-btn-label"><t>Quick actions</t></span>
                </HtButton>
                <template #target>
                    <t>Quick actions</t>
                </template>
            </HtTooltip>

            <div
                v-if="permissionsLoaded"
                class="mainbar-menu"
            >
                <div class="mainbar-menu-links">
                    <div
                        v-for="(group, i) in linksWithGroup"
                        :key="i"
                        class="mainbar-menu-group"
                    >
                        <span
                            v-if="group.label"
                            class="mainbar-menu-group-label"
                        >{{ group.label }}</span>

                        <HtMainBarMenuItem
                            v-for="item in group.items"
                            :key="item.name"
                            :item="item"
                            @onChangeSubbar="onChangeSubbar"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="mainbar-panel">
            <HtTooltip
                inverted
                position="inline-end"
                class="mainbar-panel-btn-tooltip"
            >
                <HtButton
                    size="large"
                    type="secondary"
                    class="mainbar-panel-btn"
                    @click="onCloseSubbar"
                >
                    <HtIcon name="arrow-left" />
                    <span class="mainbar-panel-btn-label"><t>Back to menu</t></span>
                </HtButton>
                <template #target>
                    <t>Back to menu</t>
                </template>
            </HtTooltip>
            <HtMainBarMenuItem
                v-for="(item) in subbarItems"
                :key="item.name"
                :item="item"
            />
        </div>
    </div>
</template>

<script>
import ModalMixin from '@/components/globals/modals/modalMixin';
import QuickActionsModal from '@/components/globals/modals/QuickActions/QuickActionsModal.vue';
import HtButton from '@/components/miscellaneous/HtButton.vue';
import HtMainBarMenuItem from './item/HtMainBarMenuItem.vue';

export default {
    name: 'HeadBarMenu',
    components: {
        HtMainBarMenuItem,
        HtButton,
    },
    mixins: [ModalMixin],
    permissions: [
        'PageDashboard',
        'PageDashboardStatistics',
        'PagePrograms',
        'PageProfileAdministrative',
        'PageResources',
        'PageCompany',
        'PageTeam',
        'PageSettings',
        'PageDashboardPrograms',
    ],

    shared: {
        session: {},
        socket: {
            chatOpened: {
                type: Boolean,
                default: false,
            },
            count: {
                type: Number,
                default: 0,
            },
        },
    },

    data() {
        return {
            currentSubbar: null,
            showSubbar: false,
        };
    },

    computed: {
        subbarItems() {
            if (!this.currentSubbar) return [];
            return this.linksWithGroup.map((g) => g.items).flat().find((i) => i.name === this.currentSubbar)?.children;
        },
        canSeeQuickActionButton() {
            return this.$can('PageBulkEnrolling') || this.$canRead('AbstractCanSelfEnroll');
        },
        pagesWithGroup() {
            return [
                {
                    items: [
                        {
                            name: 'PagePiloting',
                            icon: 'dashboard-dots',
                            label: 'Piloting',
                            customPermission: () => this.$can('PageDashboardStatistics'),

                        },
                        {
                            name: 'PageDashboardActions',
                            icon: 'home-simple',
                            label: 'Dashboard',
                        },
                        {
                            name: 'PageActions', icon: 'flash', label: 'Actions', withoutPermission: true,
                        },
                        {
                            name: 'PageProfileAdministrative',
                            icon: 'folder',
                            label: 'Administrative',
                            params: { company_user_id: this.shared.session.companyUser.id },
                            customPermission: () => this.$can('PageProfileAdministrative', { company_user_id: this.shared.session.companyUser.id }) && !this.$canRead('AbstractWidgetChecklistOnboardee') && this.shared.session.companyUser.is_onbordee,
                        },
                        { name: 'messages', withoutPermission: true },
                        { name: 'notifications', withoutPermission: true },
                    ],
                },
                {
                    label: 'Configuration',
                    items: [
                        { name: 'PagePrograms', icon: 'stats-up-square', label: 'Journeys' },
                        {
                            name: 'ProgramsTracking', icon: 'stat-up', label: 'Journeys tracking', customPermission: () => this.$can('PageDashboardPrograms'),
                        },
                        {
                            name: 'PageResources',
                            icon: 'database',
                            label: 'Resources',
                            children: [
                                {
                                    name: 'PageResourcesDocuments',
                                    icon: 'submit-document',
                                    label: 'Documents',
                                },
                                {
                                    name: 'PageResourcesTrainings',
                                    icon: 'bookmark-book',
                                    label: 'Trainings',
                                },
                                {
                                    name: 'PageResourcesSurveys',
                                    icon: 'chat-bubble-question',
                                    label: 'Surveys',
                                },
                                {
                                    name: 'PageResourcesQuizzes',
                                    icon: 'chat-lines',
                                    label: 'Quizzes',
                                },
                                {
                                    name: 'PageResourcesEvents',
                                    icon: 'group',
                                    label: 'Events',
                                },
                                {
                                    name: 'PageResourcesSoftware',
                                    icon: 'pc-mouse',
                                    label: 'Software',
                                },
                                {
                                    name: 'PageResourcesEquipments',
                                    icon: 'computer',
                                    label: 'Equipments',
                                },
                                {
                                    name: 'PageResourcesRequirements',
                                    icon: 'journal',
                                    label: 'Requirements',
                                },
                                {
                                    name: 'PageResourcesEmails',
                                    icon: 'mail',
                                    label: 'Emails',
                                },
                                {
                                    name: 'PageResourcesActions',
                                    icon: 'task-list',
                                    label: 'Actions',
                                },
                                {
                                    name: 'PageResourcesNudges',
                                    icon: 'playstation-gamepad',
                                    label: 'Challenges',
                                },
                            ],
                        },
                        {
                            name: 'PageSettings',
                            icon: 'settings',
                            label: 'Settings',
                            children: [
                                {
                                    name: 'PageSettingsUsers',
                                    icon: 'group',
                                    label: 'Users',
                                },
                                {
                                    name: 'PageSettingsRolesAndPermissions',
                                    icon: 'user-crown',
                                    label: 'Roles',
                                },
                                {
                                    name: 'PageSettingsEntities',
                                    icon: 'city',
                                    label: 'Entities',
                                },
                                {
                                    name: 'PageSettingsCompany',
                                    icon: 'media-image',
                                    label: 'Platform',
                                },
                                {
                                    name: 'PageSettingsEnterprisePage',
                                    icon: 'journal-page',
                                    label: 'EnterprisePage',
                                },
                                {
                                    name: 'PageSettingsNotifications',
                                    icon: 'bell-notification',
                                    label: 'Notifications',
                                },
                                {
                                    name: 'PageSettingsPreboarding',
                                    icon: 'multi-bubble',
                                    label: 'Welcome Chatbot',
                                },
                                {
                                    name: 'PageSettingsCommunication',
                                    icon: 'chat-lines',
                                    label: 'Communication',
                                },
                                {
                                    name: 'PageSettingsLanguages',
                                    icon: 'translate',
                                    label: 'Languages',
                                },
                                {
                                    name: 'PageSettingsIntegrations',
                                    icon: 'puzzle',
                                    label: 'Integrations',
                                },
                                {
                                    name: 'PageSettingsDataAnalysis',
                                    icon: 'database-settings',
                                    label: 'Data analysis',
                                },
                                {
                                    name: 'PageSettingsSecurityAndPrivacy',
                                    icon: 'lock-square',
                                    label: 'Security & Privacy',
                                },
                                {
                                    name: 'PageSettingsCustomFields',
                                    icon: 'input-field',
                                    label: 'Custom fields',
                                },
                                {
                                    name: 'PageSettingsLogs',
                                    icon: 'code-brackets-square',
                                    label: 'Logs',
                                },
                                {
                                    name: 'PageSettingsAi',
                                    icon: 'magic-wand',
                                    label: 'AI',
                                },
                            ],
                        },
                    ],
                },
                {
                    label: this.shared.session.company.name,
                    items: [
                        { name: 'PageTeam', icon: 'user', label: 'Team' },
                        { name: 'PageCompany', icon: 'building', label: 'Company' },

                    ],
                },
            ];
        },
        isLoaded() {
            return this.shared.session.company && this.shared.session.companyUser;
        },
        goToLogin() {
            return {
                name: 'Login',
            };
        },
        linksWithGroup() {
            return this.pagesWithGroup.reduce((groups, group) => {
                const itemsInGroup = group.items.reduce((items, page) => {
                    const itemCanBeView = page.withoutPermission || page.customPermission?.() || this.$can(page.name);

                    if (itemCanBeView) {
                        const subItems = page.children?.reduce((children, child) => {
                            const subItemCanBeView = child.withoutPermission || child.customPermission?.() || this.$can(child.name);
                            if (subItemCanBeView) {
                                children.push({
                                    ...child,
                                    name: child.name.replace('Page', ''),
                                    params: child.params || {},
                                });
                            }

                            return children;
                        }, []);

                        const item = {
                            ...page,
                            name: page.name.replace('Page', ''),
                            params: page.params || {},
                            children: subItems || [],
                        };
                        items.push(item);
                    }

                    return items;
                }, []);

                if (itemsInGroup.length) {
                    groups.push({
                        label: group.label,
                        items: itemsInGroup,
                    });
                }
                return groups;
            }, []);
        },
    },

    methods: {
        openQuickActionModal() {
            this.openCenterModal(QuickActionsModal);
        },
        onChangeSubbar(subbar) {
            this.currentSubbar = subbar;
            this.showSubbar = true;
        },
        async onCloseSubbar() {
            this.showSubbar = false;
            // Wait for the subbar to close before emptying it (wait for the 0.3s CSS transition)
            await new Promise((r) => { setTimeout(r, 300); });
            this.currentSubbar = null;
        },
    },
};
</script>

<style lang="scss" scoped src="./HtMainBarMenu.scss"></style>
