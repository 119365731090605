<template>
    <div>
        <div
            class="task-wrapper"
            :class="{'task-sub-task': subtask || hasDependent}"
            :data-cy="`${cypress}-item`"
        >
            <div
                class="task-inside"
                :class="{'sub-task-wrapper': subtask || hasDependent}"
            >
                <HtIcon
                    v-if="subtask || hasDependent"
                    name="long-arrow-down-right"
                    size="24"
                    class="subtask-icon"
                />
                <div
                    class="task-tile"
                    :class="{
                        'is-dependent': isDependent,
                        'is-not-clickable': !isClickable
                    }"
                    :data-cy="`${cypress}-click`"
                    @click.stop="onClick()"
                >
                    <div class="task-header">
                        <div
                            v-if="showMultiSelect && isSelectable"
                            class="multiselect-checkbox"
                            :data-cy="`${cypress}-select`"
                            @click.stop="onSelectedAction"
                        >
                            <div class="multiselect-checkbox-wrapper">
                                <div :class="{'checked': isSelected == true, 'half': isSelected == 2}" />
                            </div>
                        </div>
                        <div class="wrapper-task-icon">
                            <HtEmojiIcon
                                size="32"
                                font-size="13"
                                :style="{backgroundColor: resourcesInfo[resource].color }"
                            >
                                <HtIcon
                                    :name="resourcesInfo[resource].iconName || 'task-list'"
                                    stroke-width="2"
                                    size="16"
                                    :style="{color: resourcesInfo[resource].iconColor }"
                                />
                            </HtEmojiIcon>
                        </div>
                        <div class="wrapper-task-desc">
                            <div class="task-title">
                                {{ title }}
                            </div>
                            <div
                                v-if="isEventNotToPlan(task)"
                                class="event-time"
                            >
                                {{ task.planning_event.time_start }} - {{ task.planning_event.time_end }}
                            </div>
                            <div class="task-subtitle">
                                <span
                                    v-if="isLate"
                                    class="task-subtitle-late"
                                >
                                    <span>{{ translate('Late ({date})', {
                                        date: getFormattedDate(task.datetime_end),
                                    }) }}</span>
                                </span>
                                <span v-else>
                                    {{ getFormattedDate(task.datetime_end) }}
                                </span>
                            </div>
                        </div>
                        <div
                            v-if="showButtonRight"
                            :data-cy="`${cypress}-buttons`"
                            class="wrapper-task-status"
                        >
                            <HtAvatarGroup
                                v-if="participants && participants.length"
                                :users="$Utils.getAvatarGroupData(participants.map(a => a.company_user))"
                            />
                            <div
                                v-if="canValidate || isDone || isCancelled"
                                @click.stop="onToggle(!isDone)"
                            >
                                <TaskStatus
                                    v-if="isCancelled"
                                    status="unavailable"
                                />
                                <TaskStatus
                                    v-else-if="isDone"
                                    status="done"
                                />
                                <TaskStatus
                                    v-else-if="!isTogglable"
                                    status="disabled"
                                />
                                <TaskStatus
                                    v-else
                                    status="pending"
                                />
                            </div>
                            <div
                                v-else-if="!showWrapper"
                                class="icon-validate"
                            >
                                <HtIcon
                                    name="nav-arrow-right"
                                    size="24"
                                    class="toggle-icon"
                                />
                            </div>
                            <div
                                v-else-if="showWrapper"
                                class="icon-validate grey"
                            >
                                <HtIcon
                                    name="nav-arrow-right"
                                    size="24"
                                    class="toggle-icon"
                                    :class="{'opened': isToggled}"
                                />
                            </div>
                        </div>
                        <div
                            v-else-if="isDependent"
                            class="wrapper-task-status"
                        >
                            <HtIcon
                                name="lock"
                                size="24"
                                class="lock-icon"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="$slots.subItems"
                class="subitems"
            >
                <slot name="subItems" />
            </div>
            <div
                v-if="$slots['dependency']"
                class="children-wrapper"
            >
                <slot :name="`dependency`" />
            </div>
        </div>
    </div>
</template>
<script>
import CypressMixin from '@/mixins/CypressMixin';
import TaskStatus from '@/components/pages/actions/all/item/TaskStatus.vue';

export default {
    name: 'ActionProgramListItems',
    components: {
        TaskStatus,
    },
    mixins: [CypressMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        showWrapper: {
            type: Boolean,
            default: false,
        },
        isTogglable: {
            type: Boolean,
            default: true,
        },
        isToggled: {
            type: Boolean,
            default: false,
        },
        canValidate: {
            type: Boolean,
            default: true,
        },
        participants: {
            type: Array,
            default: () => [],
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        isSelectable: {
            type: Boolean,
            default: true,
        },
        isSelected: {
            type: [Boolean, Number],
            default: false,
        },
        showIconResource: {
            type: Boolean,
            default: false,
        },
        resource: {
            type: String,
            default: 'custom',
        },
        hasDependent: {
            type: Boolean,
            default: false,
        },
        isClickable: {
            type: Boolean,
            default: true,
        },
        status: {
            type: String,
            default: 'pending',
        },
        validatorType: {
            type: String,
            default: 'human',
        },
        isDependent: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        subtask: {
            type: Boolean,
            default: false,
        },
    },

    shared: {
        program: {
            isLoading: {
                type: Boolean,
                default: false,
            },
        },
    },

    data() {
        return {
            resourcesInfo: {
                custom: {
                    label: 'Action',
                    name: 'company_program_task',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#FFE3AC',
                    iconName: 'task-list',
                    iconColor: '#997328',
                },
                company_survey: {
                    label: 'Survey',
                    name: 'company_survey',
                    modalSize: 'medium',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => false,
                    color: '#FFDCCE',
                    iconName: 'chat-bubble-question',
                    iconColor: '#9A3F21',
                },
                company_user_quiz: {
                    label: 'Quiz',
                    name: 'company_quiz',
                    modalSize: 'large',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#EDE5DE',
                    iconName: 'chat-lines',
                    iconColor: '#694F4F',
                },
                company_nudge: {
                    label: 'Nudge',
                    name: 'company_nudge',
                    modalSize: 'medium',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#F5F4F4',
                    iconName: 'playstation-gamepad',
                    iconColor: '#504F4F',
                },
                company_doc: {
                    label: 'Document',
                    name: 'company_shared_document',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: () => true,
                    color: '#FFE2E2',
                    iconName: 'submit-document',
                    iconColor: '#B73E23',
                },
                company_document: {
                    label: 'Training',
                    name: 'company_document',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#D7F5F3',
                    iconName: 'bookmark-book',
                    iconColor: '#4F7075',
                },
                company_planning: {
                    label: 'Event',
                    name: 'company_planning',
                    modalSize: 'small',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#D9EEFF',
                    iconName: 'group',
                    iconColor: '#305E95',
                },
                company_email_custom: {
                    label: 'Email',
                    name: 'company_email_custom',
                    modalSize: 'medium',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#FDF2DD',
                    iconName: 'mail',
                    iconColor: '#E6A456',
                },
                company_equipment: {
                    title: this.translate('Install the following equipment | Install the following equipments', { count: this.task.tasks?.length }),
                    label: 'Equipment',
                    name: 'company_equipment',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: () => true,
                    color: '#E3DCFF',
                    iconName: 'computer',
                    iconColor: '#51397A',
                },
                company_software: {
                    title: this.translate('Install the following software | Install the following softwares', { count: this.task.tasks?.length }),
                    label: 'Software',
                    name: 'company_software',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: (task) => task.software?.type === 'company_software_custom_http',
                    color: '#D0FFF4',
                    iconName: 'pc-mouse',
                    iconColor: '#31B59E',
                },
                company_requirement_category: {
                    title: this.translate('Administrative file to be completed | Administrative files to be completed', { count: this.task.tasks?.length }),
                    label: 'Requirement',
                    name: 'company_requirement_category',
                    modalSize: 'small',
                    validateOnClick: false,
                    useFullTitle: true,
                    isSelectable: () => false,
                    color: '#FFF9C7',
                    iconName: 'journal',
                    iconColor: '#A69712',
                },
            },
            showSubTasks: false,
            statusTasks: {},
        };
    },

    computed: {
        isDone() {
            return this.status === 'done';
        },

        isPending() {
            return this.status === 'pending';
        },

        isCancelled() {
            return this.status === 'cancelled';
        },

        showButtonRight() {
            if (this.showMultiSelect) {
                return this.showWrapper;
            }
            return this.isClickable;
        },
        isGroupedTask() {
            return !!this.task.tasks?.length;
        },
        isLate() {
            return this.$Utils.moment(this.task.datetime_end) <= this.$Utils.moment();
        },
    },
    mounted() {
        this.setStatusTask();
    },
    methods: {
        setStatusTask() {
            if (this.isGroupedTask) {
                for (let i = 0; i < this.task.tasks.length; i += 1) {
                    this.$set(this.statusTasks, this.task.tasks[i].id, this.task.tasks[i].status);
                }
            } else {
                this.$set(this.statusTasks, this.task.id, this.task.status);
            }
        },
        onSelectedAction() {
            this.$emit('onSelectedAction');
        },
        onToggle(status) {
            if (this.isTogglable && (this.isDone || this.isPending)) {
                this.$emit('onToggle', status);
            }
        },
        onClick() {
            if (this.isClickable) {
                this.$emit('onClick');
            }
        },
        isEventNotToPlan(task) {
            return task.planning_event && !task.planning_event?.is_delegated;
        },
        getFormattedDate(date) {
            return this.$Utils.momentLocalized(date, this.shared.session.companyUser.company_language.key).format('DD MMMM');
        },
    },
};
</script>

<style lang="scss" scoped src="../all/item/tasksListItem.scss" />
