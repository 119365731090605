<template>
    <div>
        <EnrollingFinalized
            v-if="showFinalize"
            :user="userProgram.company_user"
            :user-program="userProgram"
        />
        <NewPageWithSidebar v-else-if="!oldUi">
            <template #sidebar>
                <HtStepperSidebar
                    v-model="stepIndex"
                    :steps="steps"
                    @back="onBack"
                >
                    <HtStepperItem
                        v-for="(step, index) in steps"
                        :key="index"
                        :step="index"
                        :label="step.label"
                        :disabled="step.disabled"
                        :steps="steps"
                    />
                </HtStepperSidebar>
            </template>
            <template>
                <div>
                    <component
                        :is="currentComponent"
                        :program-id="programId"
                        :user-id="userId"
                        :user-program-id="userProgramId"
                        :program-type="programType"
                        @on-prev="onPrev"
                        @on-next="onNext"
                    />
                </div>
            </template>
        </NewPageWithSidebar>
        <PageWithSidebar v-else-if="oldUi">
            <template #sidebar>
                <HtStepperSidebar
                    v-model="stepIndex"
                    :steps="steps"
                    @back="onBack"
                >
                    <HtStepperItem
                        v-for="(step, index) in steps"
                        :key="index"
                        :step="index"
                        :label="step.label"
                        :disabled="step.disabled"
                        :steps="steps"
                    />
                </HtStepperSidebar>
            </template>
            <template>
                <div>
                    <component
                        :is="currentComponent"
                        :program-id="programId"
                        :user-id="userId"
                        :user-program-id="userProgramId"
                        :program-type="programType"
                        @on-prev="onPrev"
                        @on-next="onNext"
                    />
                </div>
            </template>
        </PageWithSidebar>
    </div>
</template>

<script>
import HtStepperSidebar from '@/components/globals/HtStepperSidebar.vue';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import CompanyProgram from '@/models/CompanyProgram';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import NewPageWithSidebar from '@/layouts/NewPageWithSidebar.vue';
import HtStepperItem from '@/components/globals/HtStepperItem.vue';
import CompanyProgramNotificationTemplate from '@/models/CompanyProgramNotificationTemplate';
import EnrollingNew from './EnrollingNew.vue';
import EnrollingTeam from './EnrollingTeam.vue';
import EnrollingPackage from './EnrollingPackage.vue';
import EnrollingMail from './EnrollingMail.vue';
import EnrollingProgram from './EnrollingProgram.vue';
import EnrollingFinalized from './EnrollingFinalized.vue';
import EnrollingSynthese from './EnrollingSynthese.vue';

export default {
    name: 'Enrolling',
    permissions: ['PageEnrolling'],
    components: {
        PageWithSidebar,
        NewPageWithSidebar,
        HtStepperItem,
        HtStepperSidebar,
        HtPageTitle,
        EnrollingNew,
        EnrollingTeam,
        EnrollingPackage,
        EnrollingMail,
        EnrollingProgram,
        EnrollingFinalized,
        EnrollingSynthese,
    },

    data() {
        return {
            stepIndex: 0,
            showFinalize: false,
            program: new CompanyProgram(['id', 'updated_at']).with({
                locales: (query) => query.select(['language_key', 'name']),
                notifications: (query) => {
                    query
                        .select(['id', 'template_slug']).with({
                            templates: (query) => query
                                .select(['id', 'is_enabled', 'canal_type']),
                        })
                        .where([['template_slug', '=', CompanyProgramNotificationTemplate.TEMPLATE_INVITE]]);
                },
            }),
            userProgram: this.initUserProgram(),
            steps: [
                {
                    value: 'EnrollingNew',
                    disabled: false,
                    label: this.translate('Information'),
                },
                {
                    value: 'EnrollingTeam',
                    disabled: true,
                    label: this.translate('Team'),
                },
                {
                    value: 'EnrollingPackage',
                    disabled: true,
                    label: this.translate('Package'),
                },
                // TODO: conditionner cette partie du code
                {
                    value: 'EnrollingMail',
                    disabled: true,
                    label: this.translate('Mail'),
                },
                {
                    value: 'EnrollingProgram',
                    disabled: true,
                    label: this.translate('Program'),
                },
            ],
        };
    },

    computed: {
        isEmailOrSmsEnabled() {
            const { templates } = this.program.notifications.models[0];
            return templates.models.some((template) => template.is_enabled);
        },
        hasPrev() {
            const newIdx = this.stepIndex - 1;
            return newIdx >= 0;
        },

        hasNext() {
            return Boolean(this.stepIndex < this.steps.length - 1);
        },

        currentComponent() {
            return this.steps[this.stepIndex].value;
        },

        programId() {
            if ('program_id' in this.$route.query) {
                return parseInt(this.$route.query.program_id, 10);
            } if (this.userProgram.isLoaded()) {
                return this.userProgram.company_program.id;
            }
            return null;
        },

        userProgramId() {
            if ('user_program_id' in this.$route.query) {
                return parseInt(this.$route.query.user_program_id, 10);
            } if (this.userProgram.isLoaded()) {
                return this.userProgram.id;
            }
            return null;
        },

        userId() {
            if ('user_id' in this.$route.query) {
                return parseInt(this.$route.query.user_id, 10);
            } if (this.userProgram.isLoaded()) {
                return this.userProgram.company_user_id;
            }
            return null;
        },

        programType() {
            const program = this.$store.state.programs.programs.find(
                (program) => this.programId === program.id,
            );
            if (program) {
                return program.type.slug;
            }
            return '';
        },

        oldUi() {
            return this.$store.state.ui.oldUi;
        },
    },
    watch: {
        '$route.query': function () {
            const userProgramId = this.$route.query?.user_program_id;
            if (userProgramId) {
                this.loadUserProgram(userProgramId).then(() => {
                    this.redirect();
                    this.setPage({
                        title: this.translate('Invitation {program_name}', {
                            program_name: this.userProgram.company_program.name_translated,
                        }),
                    });
                });
            } else {
                this.userProgram = this.initUserProgram();
            }
        },
    },

    async created() {
        const params = this.$route.query;
        const isNewEnrolling = 'program_id' in params;
        let title = '';

        if (isNewEnrolling) {
            this.program.where(['id', '=', params.program_id]);

            await this.program.get(); // TODO Ludo : retirer ca
            await this.injectSynthesisStep();
            title = this.program.name_translated; // TODO Ludo : setter le title via le store

            if (!this.isEmailOrSmsEnabled) {
                this.removeMailStep();
            }
        } else {
            await this.loadUserProgram(params.user_program_id);
            this.program.where(['id', '=', this.userProgram.company_program_id]);

            await this.program.get(); // TODO Ludo : retirer ca
            await this.injectSynthesisStep();
            title = this.userProgram.company_program.name_translated;

            if (!this.isEmailOrSmsEnabled) {
                this.removeMailStep();
            }
            this.redirect();
        }

        this.setPage({
            title: this.translate('Invitation {program_name}', {
                program_name: title,
            }),
        });
    },

    methods: {
        async injectSynthesisStep() {
            await this.$store.dispatch('programs/fetchPrograms');
        },

        removeMailStep() {
            const index = this.steps.findIndex((step) => step.value === 'EnrollingMail');
            if (index !== -1) {
                this.steps.splice(index, 1);
            }
        },

        onPrev() {
            if (this.hasPrev) {
                this.stepIndex--;
            }
        },

        async onNext(userProgramId) {
            if (userProgramId) {
                await this.loadUserProgram(userProgramId);
            }

            if (this.hasNext) {
                this.stepIndex++;
            } else {
                this.showFinalize = true;
            }
        },

        loadUserProgram(userProgramId) {
            return new Promise((resolve) => {
                this.userProgram.where(['id', '=', userProgramId]);
                this.userProgram.get().then(() => {
                    if (this.userProgram.status === 'active') {
                        this.$router.push({
                            name: 'ProfileProgram',
                            params: {
                                company_user_id:
                                    this.userProgram.company_user_id,
                                company_user_program_id: this.userProgram.id,
                            },
                        });
                    }
                    resolve();
                });
            });
        },

        initUserProgram() {
            return new CompanyUserProgram([
                'id',
                'status',
                'has_default_resources',
                'company_user_id',
                'mail_subject',
                'mail_content',
                'sms_content',
                'updated_at',
            ]).with({
                companyProgram: (query) => query.select(['id', 'updated_at']).with({
                    locales: (query) => query.select(['language_key', 'name']),
                    htProgramType: (query) => query.select(['slug']),
                }),
                companyUser: (query) => query.select(['id', 'company_user_id', 'fullname', 'phone_number']),
            });
        },

        redirect() {
            let unlockSteps = 0;
            if (this.userProgram.has_default_resources) {
                if (this.userProgram.mail_subject) {
                    unlockSteps = 4;
                } else {
                    unlockSteps = 2;
                }
            }
            while (unlockSteps > 0) {
                this.steps[unlockSteps].disabled = false;
                unlockSteps--;
            }
        },

        onBack() {
            if (this.userProgramId != null) {
                this.$Notifier('App').showInfo(
                    this.translate('The draft has been successfully saved'),
                );
            }
        },
    },
};
</script>
