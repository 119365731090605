var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-card"},[_c('div',{staticClass:"user-header"},[_c('router-link',{staticClass:"user-header-link",attrs:{"to":{
                name: 'Profile',
                params: { company_user_id: _vm.user.id },
            }}},[_c('HtAvatar',{attrs:{"user":_vm.$Utils.getAvatarData(_vm.user)}}),_c('div',{staticClass:"text"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname.toUpperCase())+" ")]),(_vm.user.job)?_c('div',{staticClass:"job"},[_vm._v(" "+_vm._s(_vm.user.job.name)+" ")]):_vm._e()]),_c('HtIcon',{attrs:{"name":"nav-arrow-right","size":"20"}})],1)],1),(_vm.tabs.length > 1)?_c('div',{staticClass:"tabs"},[_c('HtTabs',{scopedSlots:_vm._u([{key:"default",fn:function(){return _vm._l((_vm.tabs),function(tab){return _c('button',{key:tab.label,staticClass:"tab",class:{active: _vm.currentProgramToShow === tab.id},attrs:{"type":"button"},on:{"click":function($event){_vm.currentProgramToShow = tab.id}}},[_vm._v(" "+_vm._s(tab.label)+" ")])})},proxy:true}],null,false,1211774155)})],1):_vm._e(),(_vm.currentProgramToShow)?_c('div',{staticClass:"list-tasks-user"},_vm._l((_vm.sortedAndFilteredTasksByProgramId(_vm.currentProgramToShow).slice(0, _vm.nbTaskMinimum)),function(task){return _c('TasksListItem',{key:`task-${task.id}`,staticClass:"task-item",attrs:{"task":task,"minimal-layout":"","hide-program":true},on:{"update":_vm.updateTask,"updateStatus":_vm.updateTaskStatus,"delete":_vm.deleteTask}})}),1):_vm._e(),(!_vm.activeProgramsWithoutDefault.length)?_c('div',{staticClass:"no-task-info"},[_c('t',[_vm._v("No current program")])],1):(!_vm.user.nb_tasks)?_c('div',{staticClass:"no-task-info"},[_c('t',[_vm._v("No task left to do")])],1):_vm._e(),(_vm.tabs.find(t => t.id === _vm.currentProgramToShow))?_c('router-link',{staticClass:"user-footer",attrs:{"to":{
            name: 'ProfileProgramDetail',
            params: {
                company_user_id: _vm.user.id,
                company_user_program_id: _vm.currentProgramToShow,
            },
        }}},[_vm._v(" "+_vm._s(_vm.translate('See {programName}', { programName: _vm.tabs.find(t => t.id === _vm.currentProgramToShow).label }))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }