<template>
    <div class="piloting-widget">
        <header class="widget-header">
            <span class="widget-title"><t>Distribution per program</t></span>
            <span class="widget-subtitle">
                <t>Discover the breakdown of your different programs</t>
            </span>
        </header>
        <div class="widget-body">
            <div v-if="!loading">
                <div class="wrapper-programs-distribution">
                    <div
                        ref="programsDoughnut"
                        class="programs-distribution-doughnut"
                    >
                        <Doughnut
                            :chart-options="chartOptions"
                            :chart-data="chartData"
                        />
                    </div>
                    <div class="programs-distribution-legend">
                        <div
                            v-for="(program, index) in programs"
                            :key="program.id"
                            class="program-distribution"
                        >
                            <span class="program-left">
                                <span
                                    class="program-color"
                                    :style="{backgroundColor: getChartColor(index)}"
                                />
                                <span class="program-name">{{ program.name_translated }}</span>
                            </span>
                            <span class="program-users">
                                <HtBadge theme="branding">
                                    {{ program.user_programs_in_progress_count }}
                                </HtBadge>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <HtIconLoading v-else />
        </div>
        <router-link
            class="widget-footer"
            :to="{ name: 'Programs' }"
        >
            <span><t>See more details</t></span>
        </router-link>
    </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
    name: 'ProgramsDistribution',
    components: {
        Doughnut,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        programs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                cutout: '65%',
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        backgroundColor: '#272727',
                        cornerRadius: 8,
                        caretSize: 7,
                        padding: 8,
                        displayColors: false,
                    },
                },
            },
            chartColors: ['#A4A4A4', '#FBA4C3', '#3B74E6', '#A1E4CA', '#9CD8ED'],
            chartDoughnut: null,
            chartBorderRadius: 10,
        };
    },
    computed: {
        chartData() {
            const labels = this.programs.map((program) => program.name_translated);
            const programData = this.programs.map(
                (program) => program.user_programs_in_progress_count,
            );
            return {
                labels,
                datasets: [{
                    data: programData,
                    backgroundColor: this.chartColors,
                    borderRadius: this.chartBorderRadius,
                    borderWidth: this.chartBorderRadius / 2,
                    hoverBorderColor: '#DDDDDD',
                }],
            };
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        getChartColor(index) {
            return this.chartColors[index % this.chartColors.length];
        },
        onResize() {
            this.chartBorderRadius = Math.round(
                (this.$refs.programsDoughnut?.offsetWidth || 400) * 0.025,
            );
        },
    },
};
</script>

<style lang="scss" scoped src="./PilotingWidget.scss" />
<style lang="scss" scoped>
@import "~@/styles/ds/tokens";
@import "~@/styles/ds/typography";
@import '~@/styles/var';

.wrapper-programs-distribution {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 30px 0;
}
.programs-distribution-doughnut {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
.programs-distribution-legend {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.program-distribution {
    display: flex;
    align-items: center;
    gap: 16px;
}
.program-left {
    min-width: 0;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 8px;
}
.program-color {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    border-radius: 8px;
}
.program-users {
    flex: 0 0 auto;
}
.program-name {
    @include ht-body;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media (min-width: $tablet-large){
    .wrapper-programs-distribution {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0;
    }
    .programs-distribution-doughnut {
        flex: 0 0 auto;
        width: 50%;
        max-width: none;
        padding: 0 30px;
    }
    .programs-distribution-legend {
        flex: 0 0 auto;
        width: 50%;
        padding: 0 0 0 20px;
    }
}
</style>
