<template>
    <div>
        <History v-if="oldUi" />
        <NewHistory v-else />
    </div>
</template>
<script>
import History from './History.vue';
import NewHistory from './NewHistory.vue';

export default {
    name: 'HistoryPage',
    components: {
        History,
        NewHistory,
    },
    computed: {
        oldUi() {
            return this.$store.state.ui.oldUi;
        },
    },
};
</script>
